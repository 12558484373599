import { combineReducers } from "redux";
import { walletReducer } from "./Wallet";
import { adminPanelReducer } from "./AdminPanel";
import { phaseReducer } from ".//Phase";
import { coinReducer } from ".//CoinMarket";
// import { kycReducer } from ".//KYC";

export const rootReducer = combineReducers({
  wallet: walletReducer,
  adminPanel: adminPanelReducer,
  phase: phaseReducer,
  coin: coinReducer,
  // kyc: kycReducer,
});
