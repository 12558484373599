export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const getRandomBool = () => {
  return Math.random() >= 0.5;
};
//
export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const shortenAddress = (address) => {
  if (!address) return "error";
  return `${address.slice(0, 5)}...${address.slice(address.length - 4)}`;
};
export const shortenAddressLong = (address) => {
  if (!address) return "error";
  return `${address.slice(0, 10)}...${address.slice(address.length - 9)}`;
};
export const formatDateToLocalString = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
  });
};
export const localeString = (number) => {
  if (!number) return 0;
  return parseFloat(number).toLocaleString();
};
export const dollarToEuro = (value, euro) => {
  if (!value) return 0;
  if (!euro) return 0;
  return parseFloat(value * euro).toFixed(2);
};

export const euroToDollar = async (value, euro) => {
  if (!value) return 0;
  if (!euro) return 0;
  return parseFloat(value / euro);
};
