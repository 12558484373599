import { Contract, ethers } from "ethers";
import {
  saleTokenAddress,
  saleABI,
  contractTokenAddress,
  tokenABI,
  ZERO_ADDRESS,
} from "../../../utils/constants";
import { paxios } from "../../../utils/axios";

import { formatDateToLocalString, euroToDollar } from "../../../utils";
//ADMIN STARTUP
export const setAdminData = async (dispatch, library, walletAddress) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const hasRole = await saleContract.hasRole(ZERO_ADDRESS, walletAddress);
    dispatch({
      type: "ADMIN_DATA",
      payload: hasRole,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "ADMIN_ERROR",
      payload: "Error Generico desde Metamask",
    });
  }
};
//DASHBOARD
export const setAdminDashbord = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let tempObj = {};
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const currentPhaseIndex = await saleContract.currentPhaseIndex();
    const getPhase = await saleContract.getPhase(currentPhaseIndex.toString());
    tempObj.endAt = formatDateToLocalString(getPhase.endAt.toString() * 1000);
    tempObj.supply = ethers.utils.formatEther(getPhase.supply.toString());
    tempObj.price = ethers.utils.formatEther(getPhase.price.toString());
    tempObj.minimunEntry = ethers.utils.formatEther(
      getPhase.minimunEntry.toString()
    );
    tempObj.isPublic = getPhase.isPublic;
    tempObj.over = getPhase.over;

    tempObj.timelock = parseInt(getPhase.timelock.toString() / 3600 / 24);
    let message = "Data actualizada";
    dispatch({
      type: "ADMIN_DASHBOARD",
      payload: { tempObj, message },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const deletePhase = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.cancelPhase();
    const response = await transaction.wait();
    let message = "Phase Eliminada Deber Crear una en ventas";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { message, response },
    });
  } catch (error) {
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//VENTAS
export const setAdminPhase = async (dispatch, dataForm, library, euroPrice) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const phaseType = dataForm.phaseType;
    const minEntry = ethers.utils
      .parseEther(dataForm.minEntry.toString())
      .toString();
    const price = ethers.utils
      .parseEther(
        parseFloat(euroToDollar(dataForm.price, euroPrice)).toString()
      )
      .toString();
    const endAt = new Date(dataForm.endAt).getTime() / 1000;
    const supply = ethers.utils
      .parseEther(dataForm.supply.toString())
      .toString();
    const timelock = dataForm.timelock * 24 * 60 * 60;
    const timesToRelease = dataForm.timesToRelease
      .split(",")
      .sort()
      .map((x) => {
        return x * 24 * 60 * 60;
      });
    const percentsToRelease = dataForm.percentsToRelease
      .split(",")
      .map((percent) => parseFloat(percent));
    //------------------------
    let signer = library.getSigner();
    let tokenContract = new Contract(contractTokenAddress, tokenABI, signer);
    const transaction = await tokenContract.approve(saleTokenAddress, supply);
    const awaitTransaction = await transaction.wait();
    let message = "Verificando, Por favor espera";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { awaitTransaction, message },
    });
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);

    const createPhase = await saleContract.createPhase(
      phaseType,
      minEntry,
      price,
      endAt,
      supply,
      timelock,
      timesToRelease,
      percentsToRelease
    );
    const awaitTransaction2 = await createPhase.wait();

    message = "Fase fue creada Exitosamente";
    dispatch({
      type: "ADMIN_DELETE_PHASE",
      payload: { awaitTransaction2, message },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//WHITELIST
export const setWhitelistWallets = async (dispatch, library, isWallets) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const addtoWhitelist = await saleContract.addToWhitelist(
      isWallets.split(",")
    );
    const transaction = await addtoWhitelist.wait();
    let message = "Wallets agregadas exitosamente.";
    console.log(transaction);
    dispatch({
      type: "ADMIN_ADD_WHITELIST",
      payload: { transaction, message },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const getWhitelistWallets = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const wallets = await saleContract.getWhitelist();
    let message = "Whitelist Actualizada";
    dispatch({
      type: "ADMIN_GET_WHITELIST",
      payload: { message, wallets },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const deleteWhitelist = async (dispatch, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.removeWhitelistedAddress();
    const awaittransaction = await transaction.wait();
    let message = "Wallets Eliminadas";
    dispatch({
      type: "ADMIN_DELETE_WHITELIST",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//WHITHDRAW
export const setWithdraw = async (dispatch, library, isdataForm) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    const amount = ethers.utils.parseEther(isdataForm.amount.toString());
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.withdraw(isdataForm.address, amount);
    console.log(transaction);
    const awaittransaction = await transaction.wait();
    let message = `Se acaba de retirar:"${isdataForm.amount} BNB" a la wallet ${isdataForm.address} `;

    dispatch({
      type: "ADMIN_WITHDRAW",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    console.log(error.code);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};

//CONFIG
export const setDispatcher = async (dispatch, library, address) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const updateDispatcher = await saleContract.changeDispatcher(address);
    let message = `Nuevo Dispatcher:${address} `;
    dispatch({
      type: "ADMIN_NEW_DISPATCHER",
      payload: { message, updateDispatcher },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const setDateEnd = async (dispatch, library, dateEnd) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const transaction = await saleContract.changeEndDate(
      new Date(dateEnd).getTime() / 1000
    );
    const awaittransaction = await transaction.wait();
    let message = `Nuevo Fecha de Finalizacion : ${dateEnd} `;
    dispatch({
      type: "ADMIN_NEW_DATE_END",
      payload: { message, awaittransaction },
    });
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
//COMPRA EXTERNA
export const setCompraExterna = async (dispatch, dataForm, library) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    //------------------------

    const wallets = dataForm.wallets.split(",");
    const amounts = dataForm.amounts.split(",").map((amount) => {
      return ethers.utils.parseUnits(amount, "ether");
    });

    //------------------------
    let signer = library.getSigner();
    let saleContract = new Contract(saleTokenAddress, saleABI, signer);
    const addUsersToVesting = await saleContract.addUsersToVesting(
      amounts,
      wallets
    );
    let message = "Tokens en proceso de envio, espera un momento";
    dispatch({
      type: "ADMIN_CHECK",
      payload: { message },
    });
    const awaitTransaction = await addUsersToVesting.wait();
    message = "Tokens agregados exitosamente. 🎉";
    dispatch({
      type: "ADMIN_VESTING",
      payload: { awaitTransaction, message },
    });
    //------------------------
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error Generico desde Metamask",
      });
    }
  }
};
export const fetchUsers = async (dispatch) => {
  try {
    dispatch({
      type: "ADMIN_LOADING",
    });
    //------------------------
    let tempArray = [];
    const { data } = await paxios.get("/correos/getall");
    tempArray = data.data;
    let message = "Lista de usuarios cargada exitosamente 🎉";
    dispatch({
      type: "ADMIN_USER_LIST",
      payload: { tempArray, message },
    });
    //------------------------
  } catch (error) {
    console.log(error);
    if (error.code === 4001) {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Usuario Nego la transferencia",
      });
    } else {
      dispatch({
        type: "ADMIN_ERROR",
        payload: "Error al cargar la data, intente nuevamente 🙁",
      });
    }
  }
};
////RESET MESSAGE
export const resetMessage = async (dispatch) => {
  dispatch({
    type: "ADMIN_RESET_MESSAGE",
  });
};
