import axios from "axios";
const axiosParams = {
  baseURL: "https://www.yourtokencreator.com/",
  headers: { "cache-control": "no-cache", "Content-Type": "application/json" },
};
const coinParams = {
  baseURL:
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=matic-network%2Cbitcoin%2Cbinancecoin%2Cbinance-usd%2Cethereum%2Cusd-coin&order=market_cap_desc&per_page=10&page=1&sparkline=false",
};
const exchangeParams = {
  baseURL:
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=euro-coin&order=market_cap_desc&per_page=10&page=1&sparkline=false",
};

export const publicAxios = axios.create(axiosParams);
publicAxios.defaults.headers.common["cache-control"] = "no-cache";
publicAxios.defaults.headers.post["Content-Type"] = "no-cache";
publicAxios.defaults.headers.put["Content-Type"] = "no-cache";

export const privateAxios = axios.create(axiosParams);
privateAxios.defaults.headers.common["cache-control"] = "no-cache";
privateAxios.defaults.headers.post["Content-Type"] = "no-cache";
privateAxios.defaults.headers.put["Content-Type"] = "no-cache";
export const kycAxios = axios.create();
kycAxios.defaults.headers.common["cache-control"] = "no-cache";
kycAxios.defaults.headers.post["Content-Type"] = "no-cache";
kycAxios.defaults.headers.put["Content-Type"] = "no-cache";
export const coinAxios = axios.create(coinParams);
export const exchangeAxios = axios.create(exchangeParams);

export const setJWT = (jwt) => {
  privateAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};
export const set_KYC_JWT = (jwt) => {
  kycAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const codeInterceptor = (handler) => {
  privateAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            handler();
            break;
          default:
            console.log(error);
        }
      } else {
        console.log(error);
      }
      return Promise.reject(error);
    }
  ); // end use
};

export const naxios = publicAxios;
export const paxios = privateAxios;
