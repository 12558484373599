/* eslint-disable no-unused-vars */
import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Spinner from "./components/Spinner";
import Modal from "./components/Modal";
import WrongNetwork from "./components//WrongNetwork";
import { useSelector } from "react-redux";
import { shortenAddressLong } from "./utils";
import { codeInterceptor } from "./utils//axios";
import { WalletWeb3Provider } from "./context/WalletWeb3Context";
import { useDispatch } from "react-redux";
import { fetchEuroPrice } from ".//store/Reducers/CoinMarket/actions";
//
const Home = React.lazy(() => import("./pages/Home"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Payment = React.lazy(() => import("./pages/Payment"));
const AdminPanel = React.lazy(() => import("./pages/AdminPanel"));
const ListCompras = React.lazy(() => import("./pages/ListCompras"));

const App = () => {
  const dispatch = useDispatch();
  const [isModalWrongNetworkOpen, setisModalWrongNetworkOpen] = useState(false);
  const { isWrongNetwork, walletAddress } = useSelector(({ wallet }) => wallet);
  useEffect(() => {
    if (isWrongNetwork) {
      setisModalWrongNetworkOpen(() => true);
    } else {
      wrongnetworkModalClose();
    }
  }, [isWrongNetwork]);
  const wrongnetworkModalClose = () => {
    setisModalWrongNetworkOpen(() => false);
  };
  useEffect(() => {
    codeInterceptor(() => {
      dispatch({
        type: "DISCONNECT",
      });
    }, [dispatch]);
  });

  //useeffect that fetches the euro price when poage chnmages
  useEffect(() => {
    fetchEuroPrice(dispatch);
  }, []);

  return (
    <Suspense fallback={<Spinner full>Cargando...</Spinner>}>
      <WalletWeb3Provider>
        {!!walletAddress && (
          <Modal
            visible={isModalWrongNetworkOpen}
            onClose={() => wrongnetworkModalClose()}
          >
            <WrongNetwork account={shortenAddressLong(walletAddress)} />
          </Modal>
        )}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/signup" element={<SignUp />} exact />
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/payment">
              <Route index element={<Payment />} />
              <Route path=":option" element={<Payment />} />
            </Route>
            <Route path="/admin" element={<AdminPanel />} exact />
            <Route path="/list" element={<ListCompras />} exact />
          </Routes>
        </BrowserRouter>
      </WalletWeb3Provider>
    </Suspense>
  );
};

export default App;
