import { coinAxios, exchangeAxios } from "../../../utils//axios";
export const fetchPrice = async (dispatch) => {
  try {
    dispatch({
      type: "COINMARKET_LOADING",
    });
    ///////////////////////////////
    const { data } = await coinAxios.get();
    let tempArr = [];

    tempArr = data.map((item) => {
      return {
        id: item.id,
        symbol: item.symbol,
        current_price: item.current_price,
        name: coinNameHandler(item.symbol.toUpperCase()),
      };
    });
    data.map((item) => {
      if (item.symbol === "usdc") {
        tempArr.push({
          id: item.id + " polygon",

          symbol: item.symbol,
          current_price: item.current_price,
          name: "USDC (red POLYGON)",
        });
      }
    });

    let message = "Data Cargada ✅";
    dispatch({
      type: "COINMARKET_SUCCESS",
      payload: { message, tempArr },
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: "COINMARKET_ERROR",
      payload: "error",
    });
  }
};
export const fetchEuroPrice = async (dispatch) => {
  try {
    const { data } = await exchangeAxios.get();
    // console.log(data[0].current_price);

    dispatch({
      type: "COINMARKET_EURO_SUCCESS",
      payload: data[0].current_price,
    });
  } catch (error) {
    console.log(error);

    dispatch({
      type: "COINMARKET_EURO_ERROR",
      payload: 0,
    });
  }
};

const coinNameHandler = (coin) => {
  switch (coin) {
    case "MATIC":
      return "MATIC (red POLYGON)";
    case "BTC":
      return "BTC (red BTC)";
    case "BNB":
      return "BNB (red BEP20)";
    case "BUSD":
      return "BUSD (red BEP20)";
    case "ETH":
      return "ETH (red ETH)";
    case "USDC":
      return "USDC (red ETH)";
    default:
      return "MATIC (red POLYGON)";
  }
};
