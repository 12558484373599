const initialState = {
  isLoadingPhase: false,
  phasesData: false,
  error: false,
  onsuccessPhase: false,
  comprasRealizadas: false,
  transactionHash: false,
};

export const phaseReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "PHASE_LOADING":
      return {
        ...state,
        isLoadingPhase: true,
        error: false,
        onsuccessPhase: false,
        transactionHash: false,
      };
    case "PHASE_GET_PHASES_SUCCESS":
      return {
        ...state,
        isLoadingPhase: false,
        phasesData: payload.tempObj,
        comprasRealizadas: payload.comprasRealizadas,
        error: false,
        onsuccessPhase: false,
        phaseDetail: false,
      };

    case "PHASE_PURCHASE_TOKEN":
      return {
        ...state,
        isLoadingPhase: false,
        error: false,
        transactionHash: payload.transactionHash,
        onsuccessPhase: payload.message,
      };
    case "PHASE_ERROR":
      return {
        ...state,
        isLoadingPhase: false,
        error: payload,
        onsuccessPhase: false,
      };
    case "DISCONNECT_WALLET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
