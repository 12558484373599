import { MainNet, TestNet } from "../../../utils/constants";
import { naxios, paxios } from "../../../utils/axios";

export const setWalletData = async (dispatch, walletAddress, network) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    let isWrongNetwork = false;
    let chainId =
      process.env.REACT_APP_NETWORK_ENV === "mainnet"
        ? process.env.REACT_APP_MAINNET_CHAINID
        : process.env.REACT_APP_TESTNET_CHAINID;

    if (network.chainId !== parseInt(chainId)) {
      isWrongNetwork = true;
    }

    dispatch({
      type: "WALLET_DATA",
      payload: {
        walletAddress,
        isWrongNetwork,
      },
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error,
    });
  }
};
export const setWalletNetwork = async (dispatch, library) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    await library.provider.request({
      method: "wallet_addEthereumChain",
      params: [
        process.env.REACT_APP_NETWORK_ENV === "mainnet" ? MainNet : TestNet,
      ],
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: error.message,
    });
  }
};
export const setEmailLogin = async (dispatch, dataform) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await naxios.post("/correos/verifyemail", {
      correo: dataform.correo,
    });
    let correo = dataform.correo;
    dispatch({
      type: "WALLET_EMAIL",
      payload: {
        correo,
        isLogged: false,
      },
    });
  } catch (error) {
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error de conexión, intente más tarde",
    });
  }
};
export const setEmailVerify = async (dispatch, email, dataform) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await naxios.post("/correos/validatetoken", {
      correo: email,
      authCode: dataform.validCode,
      referallFriend: dataform.referallFriend,
    });
    let user = data.data;
    delete user.correo;
    delete user.jwt;
    dispatch({
      type: "WALLET_VERIFY",
      payload: {
        isLogged: true,
        user: user,
        jwt: data.data.token,
      },
    });
  } catch (error) {
    if (error?.request?.responseText) {
      let response = JSON.parse(error.request.responseText);
      dispatch({
        type: "WALLET_ERROR",
        payload: response.message,
      });
    } else {
      dispatch({
        type: "WALLET_ERROR",
        payload: "Código de verificación incorrecto ❌",
      });
    }
  }
};
export const setWalletDisconnect = async (dispatch) => {
  dispatch({
    type: "DISCONNECT_WALLET",
  });
};
export const setWalletError = async (dispatch, error) => {
  dispatch({
    type: "WALLET_ERROR",
    payload: error,
  });
};
export const updateWallet = async (dispatch, walletaddressFrom, email) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await paxios.put("/correos/update/wallet", {
      wallet: walletaddressFrom,
      correo: email,
    });
    console.log(data);
    dispatch({
      type: "WALLET_UPDATE",
      payload: "Wallet actualizada correctamente 🤙",
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error al actualizar la wallet 😔",
    });
  }
};
export const cryptoFormPayment = async (dispatch, email, isData, amount) => {
  try {
    const { data } = await paxios.post("/correos/verifypurchaseprocess", {
      correo: email,
      wallet: isData.walletaddressFrom,
      cantidad: amount,
      moneda: isData.coin,
    });
    console.log(data);
    // dispatch({
    //   type: "WALLET_UPDATE",
    //   payload: "Verificacion de pago en progreso 🤙",
    // });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error al verificar pago, intenta de nuevo 😔",
    });
  }
};
export const cryptoFormPaymentFinal = async (
  dispatch,
  email,
  isData,
  amount
) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await paxios.post("/correos/purchaseprocess", {
      correo: email,
      wallet: isData.walletaddressFrom,
      hash: isData.hash,
      cantidad: amount,
      moneda: isData.coin,
    });
    console.log(data);
    dispatch({
      type: "WALLET_UPDATE",
      payload: "Se ha enviado confirmación de pago 🤙",
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error al verificar pago, intenta de nuevo 😔",
    });
  }
};
export const bankFormPayment = async (dispatch, email, amount) => {
  try {
    const { data } = await paxios.post("/correos/verifypurchasebankprocess", {
      correo: email,
      cantidad: amount,
      moneda: "COAST",
    });
    console.log(data);
  } catch (error) {
    console.log(error);
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error al verificar pago, intenta de nuevo 😔",
    });
  }
};
export const bankFormPaymentFinal = async (
  dispatch,
  email,
  referrall,
  amount
) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await paxios.post("/correos/purchasebankprocess", {
      correo: email,
      concepto: referrall,
      cantidad: amount,
      moneda: "COAST",
    });
    console.log(data);
    dispatch({
      type: "WALLET_UPDATE",
      payload: "Se ha enviado confirmación de pago 🤙",
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error al verificar pago, intenta de nuevo 😔",
    });
  }
};
export const walletFormPayment = async (
  dispatch,
  email,
  walletAddress,
  totalAmount,
  dataForm
) => {
  try {
    dispatch({
      type: "WALLET_LOADING",
    });
    const { data } = await paxios.post("/correos/purchasewalletprocess", {
      correo: email,
      wallet: walletAddress,
      monto: totalAmount,
      cantidad: dataForm.amount,
      moneda: "MATIC",
    });
    console.log(data);
    dispatch({
      type: "WALLET_UPDATE",
      payload: "Se ha enviado confirmación de pago 🤙",
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: "WALLET_ERROR",
      payload: "Error al verificar pago, intenta de nuevo 😔",
    });
  }
};
export const checkversion = async () => {
  const { data } = await paxios.get("/version");
};
