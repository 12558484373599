import { setJWT } from "../../../utils/axios";

const initialState = {
  isLoading: false,
  isLogged: false,
  walletAddress: false,
  isWrongNetwork: false,
  error: false,
  user: false,
  email: false,
  success: false,
};
setJWT(localStorage.getItem("jwt_token"));

export const walletReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "WALLET_LOADING":
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case "WALLET_DATA":
      return {
        ...state,
        isLoading: false,
        walletAddress: payload.walletAddress,
        isWrongNetwork: payload.isWrongNetwork,
        error: false,
      };
    case "WALLET_EMAIL":
      return {
        ...state,
        isLoading: false,
        email: payload.correo,
        isLogged: payload.isLogged,
        success:
          "Espera unos segundos, estamos enviando un código de verificación a tu correo ⏲️",
      };
    case "WALLET_UPDATE":
      return {
        ...state,
        isLoading: false,
        success: payload,
      };
    case "WALLET_VERIFY":
      setJWT(payload.jwt);
      localStorage.setItem("jwt_token", payload.jwt);
      return {
        ...state,
        isLoading: false,
        isLogged: payload.isLogged,
        user: payload.user,
      };
    case "WALLET_ERROR":
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case "DISCONNECT_WALLET":
      return {
        ...state,
        isLoading: false,
        walletAddress: false,
        isWrongNetwork: false,
        error: false,
      };
    case "DISCONNECT":
      localStorage.removeItem("jwt_token");
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
