const initialState = {
  isLoadingCoin: false,
  error: false,
  successMessage: false,
  coinPrice: false,
  euroPrice: false,
};

export const coinReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "COINMARKET_LOADING":
      return {
        ...state,
        isLoadingCoin: true,
        error: false,
        successMessage: false,
        coinPrice: false,
      };
    case "COINMARKET_SUCCESS":
      return {
        ...state,
        isLoadingCoin: false,
        error: false,
        successMessage: payload.message,
        coinPrice: payload.tempArr,
      };
    case "COINMARKET_EURO_SUCCESS":
      return {
        ...state,
        euroPrice: payload,
      };
    case "COINMARKET_EURO_ERROR":
      return {
        ...state,
        euroPrice: payload,
      };
    case "COINMARKET_ERROR":
      return {
        ...state,
        isLoadingCoin: true,
        error: payload,
        successMessage: false,
        coinPrice: false,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
