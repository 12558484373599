import React from "react";
import "./style.css";
const Icon = ({ src, lazy, imgStyle, ...props }) => {
  return (
    <div {...props}>
      <img
        draggable="false"
        loading={lazy ? "lazy" : "eager"}
        src={src}
        alt="alt name"
        className="icon--img"
        style={imgStyle}
      />
    </div>
  );
};

export default Icon;
