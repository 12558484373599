import React, { useContext } from "react";
import { setWalletNetwork } from "../../store/Reducers/Wallet/actions";
import { WalletWeb3Context } from "../../context/WalletWeb3Context";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const WrongNetwork = ({ account }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { library } = useContext(WalletWeb3Context);
  return (
    <>
      <div className="modal--container--wallet">
        <div className="modal--container--wallet--title">
          {" "}
          {t("extra.text64")} :
        </div>
        <div className="modal--container--wallet--address">
          <div className="modal--container--wallet--connected" />
          <div>{account}</div>
        </div>
      </div>

      <button className="modal--container--divider" />
      <button
        className="modal--container--button-admin"
        onClick={() => setWalletNetwork(dispatch, library)}
      >
        {t("extra.text66")}
      </button>
    </>
  );
};

export default WrongNetwork;
